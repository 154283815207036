<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改账户信息' : '添加账户信息'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="公司名称" prop="companyName">
        <el-input
          v-model="modalData.companyName"
          placeholder="请输入公司名称"
          filterable
        >
        </el-input>
      </el-form-item>

      <el-form-item label="负责人姓名" prop="name">
        <el-input
          v-model="modalData.name"
          placeholder="请输入负责人姓名"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系方式" prop="phone">
        <el-input
          v-model="modalData.phone"
          placeholder="请输入点位地址"
        ></el-input>
      </el-form-item>

      <el-form-item label="开户人" prop="accountName">
        <el-input
          v-model="modalData.accountName"
          placeholder="请输入开户人"
        ></el-input>
      </el-form-item>

      <el-form-item label="银行卡号" prop="bankCard">
        <el-input
          v-model="modalData.bankCard"
          placeholder="请输入开户人"
        ></el-input>
      </el-form-item>

      <el-form-item label="支行名称" prop="bankBranch">
        <el-input
          v-model="modalData.bankBranch"
          placeholder="请输入支行名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="备注：" prop="remark">
        <el-input
          v-model="this.modalData.remark"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
          placeholder="请输入备注"
        >
        </el-input>
      </el-form-item>

    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { downloadUrl } from "@/config/env";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  data() {
    return {
      downloadUrl: downloadUrl,
      formName: "form",
      ruleValidate: {
        companyName: [
          {
            required: true,
            message: "公司名称不能为空",
            trigger: "change",
          },
        ],
        
        phone: [
          {
            required: true,
            message: "联系方式不能为空",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "负责人姓名不能为空",
            trigger: "change",
          },
        ],
        bankCard: [
          {
            required: true,
            message: "银行卡号不能为空",
            trigger: "change",
          },
        ],
        accountName: [
          {
            required: true,
            message: "开户人不能为空",
            trigger: "change",
          },
        ],
        bankBranch: [
          {
            required: true,
            message: "银行支行不能为空",
            trigger: "change",
          },
        ]        
      },
    };
  },
  methods: {
   
    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);

        this.$emit("submit", obj);
      });
    },
  },
};
</script>
