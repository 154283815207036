<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input type="text" v-model="query.companyName" placeholder="点位名称"></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div>
      <el-button
        class="but"
        type="primary"
        @click="showAdd"
        v-auth="this.per.STATION_ADD"
        >添加点位账户</el-button
      >
    </div>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="公司名称" prop="companyName"></el-table-column>
      <el-table-column label="负责人姓名" prop="name"></el-table-column>
      <el-table-column label="联系方式" prop="phone"></el-table-column>
      <el-table-column label="开户人" prop="accountName"></el-table-column>
      <el-table-column label="卡号" prop="bankCard"></el-table-column>
      <el-table-column label="银行" prop="bankBranch"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="创建时间" prop="createTime"></el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEdit(scope.row)"
            v-auth="this.per.STATION_UPDATE"
          >
            编辑
          </span>

          <span
            class="option option-danger"
            @click="doDel(scope.row)"
            v-auth="this.per.STATION_UPDATE"
          >
            删除
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submitStation"
      :agentList="agentList"
    ></EditModal>
 
  </div>
</template>
 
<script>
import { addProvider, fetchProviderPage, delProvider, updateProvider } from "@/api/station";
import EditModal from "./component/EditModal";
import { areas } from "@/common/area.js";
import { mapState } from "vuex";
export default {
  components: { EditModal },

  data() {
    return {
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        status: 1,
      },

      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL"
      },
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    

    showAdd() {
      this.modalData = {
        stationImg: "",
      };
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    showEdit(data) {
      this.modalData = {
        ...data,
      };
      this.modalType = "edit";

      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal(this.modalData);
    },

    doDel(row) {
      this.$confirm("是否要删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        delStation({ id: row.id }).then((res) => {
          this.$message.success("删除成功");
          this.getList();
        });
      });
    },

    submitStation(data) {
      let request = updateProvider;
      let msg = "编辑成功";
      if (this.validatenull(data.id)) {
        request = addProvider;
        msg = "添加成功";
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchProviderPage({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records || [];
        this.total = res.data.data.total;
      });
    },

    resetPage() {
      this.dataSource = [];
      this.pages = {
        pageIndex: 1,
        pageSize: 10,
      };
      this.agentMap = {};
      this.query = {};
    },
  },
};
</script>
